import { useEffect, useState } from "react";
import Button from "components/Button";
import { IconBell } from "components/Icon/IconBell";
import IconLogoTextWhite from "components/Icon/IconLogoTextWhite";
import { SkeletonCircle, SkeletonWrapper } from "components/Skeleton/Skeleton";
import { trackEvent } from "lib/amplitude";
import { useAuthStore } from "modules/Auth/authStore";
import { IconSearch } from "components/Icon/IconSearch";
import Link from "next/link";
import { useGetNotificationApi } from "./homeApi";
import { isCustomDomain } from "lib/helper";
import {
  getSubDomain,
  isMalukuURL,
  isPesawaranURL,
  isTrenggalekURL,
  useIsBusiness,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { GoKampusLogo } from "components/PageHeader/PageHeaderDesktop";
import { useRouter } from "next/router";
import { isBrowser } from "lib/ssr";
import InputSwitch from "components/Form/InputSwitch";
import { Dropdown } from "components/Dropdown/Dropdown";
import { ModalSearchBox } from "components/InstantSearchBox/ModalSearchBox";
import FieldSearch from "components/Form/FieldSearch";
import { IconLogo } from "components/Icon/IconLogo";
import { HomeLogo } from "./HomeLogo";
import useTranslation from "next-translate/useTranslation";
import { NotificationComponent } from "modules/Notification";
import Images from "next/image";
import { BackButton } from "components/PageHeader/BackButton";
import { useRedirectPreviousPath } from "modules/Auth/authHooks";
import { useSystemStore } from "components/PageHeader/systemStore";
import ToggleDarkMode from "components/Form/ToggleDarkMode";

export const HomeHeader = ({ isTransparent }) => {
  const { loginState, currentUser } = useAuthStore((state) => ({
    loginState: state.loginState,
    currentUser: state.currentUser,
  }));
  const [show, setShow] = useState(false);
  const [isShowPopupNotif, setIsShowPopupNotif] = useState(false);
  const isTenant = useIsTenant();
  const notifications = useGetNotificationApi();
  const isSubdomainTenant = useIsSubdomain();
  const isTenantURL = useIsTenant();
  const isBusinessURL = useIsBusiness();
  const router = useRouter();
  const isLMS = router.pathname.includes("/course/[courseSlug]");
  const { t } = useTranslation("common");

  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;
  // Condition for wavemaker
  const isShowLanguageToggle = subDomain == "wavemaker" ? true : false;

  useEffect(() => {
    // not fetch api notif exclude main website
    //
    // Arthur: Disabled this because double call
    // if (
    //   loginState == "LOGIN" &&
    //   !isSubdomainTenant &&
    //   !isTenantURL &&
    //   !isBusinessURL
    // ) {
    //   notifications.doFetch({});
    // }
  }, [loginState]);

  const { locale, locales, asPath, push } = router;
  //@ts-ignore
  const otherLocale = locales?.find((cur) => cur !== locale);

  const changeLanguage = () => {
    trackEvent({
      event: "language_a",
      property: {
        changed_to: otherLocale,
      },
    });
    //@ts-ignore
    push(asPath, undefined, { locale: otherLocale });
  };
  // only query on preview LMS on moodle to bottom tabs contents
  const usePreview = router?.query?.usePreview == "true";

  const renderUser = () => {
    switch (loginState) {
      case "IDLE":
      case "LOADING":
        return (
          <SkeletonWrapper>
            <SkeletonCircle size={32} />
          </SkeletonWrapper>
        );

      case "NOT_LOGIN":
        return (
          <Link
            href={"/register"}
            onClick={() => {
              trackEvent({
                event: "homepage_signup_a",
              });
            }}
          >
            <Button
              size="small"
              variant="secondary_white"
              className="!border-[1px]"
            >
              Daftar
            </Button>
          </Link>
        );
      case "LOGIN":
        const initialName = currentUser?.name
          ? currentUser?.name[0].toUpperCase() ?? "U"
          : currentUser?.email
          ? currentUser?.email[0].toUpperCase() ?? "U"
          : "U";
        const ButtonProfile = (
          <div className="group inline-block relative text-left">
            <div className="flex items-center">
              {/* {((isTenantURL && !isSubdomainTenant) ||
                isShowLanguageToggle) && (
                <InputSwitch
                  id="toggle_tenant"
                  onChange={changeLanguage}
                  className="mr-8"
                  //@ts-ignore
                  selected={locale.toUpperCase()}
                  otherValue={otherLocale.toUpperCase()}
                />
              )} */}
              {(!isTenantURL || isSubdomainTenant) && (
                <div className="cursor-pointer w-32 h-32 rounded-full flex items-center justify-center text-b1 text-purple-60 bg-purple-10">
                  {initialName}
                </div>
              )}
            </div>

            {(!isTenantURL || isSubdomainTenant) && (
              <Dropdown
                id="DropdownSettings"
                theme="shadow-dropdown p-16 hidden group-hover:block"
                included={isSubdomainTenant ? true : false}
              />
            )}
          </div>
        );

        return ButtonProfile;

      default:
        null;
    }
  };

  const totalUnreadMessage = notifications?.data?.totalUnread;

  const renderNotifications = () => {
    if (loginState == "LOGIN") {
      // Arthur: Disabled this because double call
      // return (
      //   <NotificationComponent
      //     isShowPopupNotif={isShowPopupNotif}
      //     setIsShowPopupNotif={setIsShowPopupNotif}
      //     isIconTransparent={isTransparent}
      //   />
      // );
    }
  };

  const isB2B = useIsTenant() || useIsSubdomain();
  const renderSearch = () => {
    return (
      <Link
        onClick={() => {
          trackEvent({
            event: "homepage_search_a",
          });
        }}
        // @ts-ignore
        href={isB2B ? "/search" : "/explore"}
      >
        <IconSearch size={24} color={!isTransparent ? "#44199A" : "#FFFFFF"} />
      </Link>
    );
  };

  const trackNavbar = (type: string) => {
    trackEvent({
      event: "nav_a",
      property: {
        button_type: type,
        page: router.pathname,
      },
    });
  };

  // only for maluku tenant
  const isMalukuTenant =
    typeof window !== "undefined" && isMalukuURL(window.location.hostname);
  const isPesawaranTenant =
    typeof window !== "undefined" && isPesawaranURL(window.location.hostname);
  const isTrenggalekTenant =
    typeof window !== "undefined" && isTrenggalekURL(window.location.hostname);

  const [theme] = useSystemStore((state) => [state.theme]);

  return (
    <header className={"p-16"}>
      {isLMS ? (
        <div className="flex items-center gap-16 w-full">
          <BackButton
            backTo={!usePreview ? () => router.push("/") : undefined}
          />
          {/* {isMalukuTenant ? (
            <BackButton backTo={() => router.push("/")} />
          ) : (
            <Link
              // @ts-ignore
              href={!usePreview ? "/" : "#"}
              onClick={
                !usePreview ? () => trackNavbar("goKampus Logo") : undefined
              }
            >
              <IconLogo />
            </Link>
          )} */}

          <div className="flex-1">
            <FieldSearch
              handleClick={() => setShow(true)}
              placeholder={
                useIsTenant() ? t("searchCoursesTruncate") : t("searchCourses")
              }
              isShow={show}
              setModal={(a) => setShow(a)}
              classWidth="w-full"
            />
          </div>

          <ModalSearchBox isShow={show} onClose={() => setShow(false)} />
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <Link
            // @ts-ignore
            href={!usePreview ? "/" : "#"}
            onClick={
              !usePreview ? () => trackNavbar("goKampus Logo") : undefined
            }
          >
            {!isTransparent ? (
              // NOTE: Anies check again, this is only to fixed venteny issue
              <>
                {isMalukuTenant ? (
                  <>
                    {theme == "light" && (
                      <Images
                        src={"/maluku/logo2.png"}
                        alt="app"
                        width={152}
                        height={25}
                      />
                    )}

                    {theme == "dark" && (
                      <Images
                        src={"/maluku/logo-dark-ver.png"}
                        alt="app"
                        width={152}
                        height={25}
                      />
                    )}
                  </>
                ) : isPesawaranTenant ? (
                  <Images
                    src={
                      theme == "dark"
                        ? "/pesawaran/logo-dark.svg"
                        : "/pesawaran/logo.png"
                    }
                    alt="app"
                    width={152}
                    height={25}
                  />
                ) : isTrenggalekTenant ? (
                  <Images
                    src={
                      theme == "dark"
                        ? "/trenggalek/logo-dark.svg"
                        : "/trenggalek/logo.png"
                    }
                    alt="app"
                    width={152}
                    height={25}
                  />
                ) : (
                  <HomeLogo />
                )}
              </>
            ) : (
              <IconLogoTextWhite size={32} />
            )}
          </Link>
          <div className="flex gap-16 items-center">
            {/* TEMPORARY HIDE */}
            {/* {isTenantURL || isSubdomainTenant ? (
              <InputSwitch
                id="toggle_tenant"
                onChange={changeLanguage}
                className="mr-8"
                selected={(locale as any).toUpperCase()}
                otherValue={otherLocale.toUpperCase()}
              />
            ) : null} */}
            <ToggleDarkMode isSwitch={true} />
            {!usePreview && renderNotifications()}
            {renderSearch()}
          </div>
        </div>
      )}
    </header>
  );
};
